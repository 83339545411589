Vue.component("cfour-slider", {

    delimiters: ["${", "}"],

    props: {
        imagedata: {type: Array},
        responsive: {type: [Array,Object]},
        // altText: {type: String},
        showDots: {type: Boolean, default: false},
        showNav: {type: Boolean, default: false},
        sliderid: {type: [Number, String]},
        autoplayTimeout: {type: [Number, String]},
        autoplay: {type: Boolean, default: false},
        autoheight: {type: Boolean, default: false},
        loop: {type: Boolean, default: false},
        rightToLeft: {type: Boolean, default: false},
        disableLazyLoad: {
            type: Boolean,
            default: false
        },
        enableCarousel: {type: Boolean, default: false},
        dotsClass: {type: String, default: ""},
        animateIn: {type: String, default: ""},
        animateOut: {type: String, default: ""},
        navIcon: {type: String, default: "chevron"},
        template: {type: String},
        uid:{type: String}
    },

    data() {
        return {
            $_enableCarousel: true,
            centermode: false,
            dataArray: []
        };
    },
    created() {
        this.$options.template = this.template;
        // console.log('vue slider');
        // this.$_enableCarousel = (!!this.enableCarousel && this.dataArray.length > 1);
        this.$_enableCarousel = true;
        this.dataArray = this.imagedata;
        this.checkImageArray();
        let config = {};
        for(let breakpoint of this.responsive){
            config[breakpoint.breakpoint+''] = {
                dots: !!breakpoint.dots,
                nav: !!breakpoint.dots,
                center: !!breakpoint.center,
                items: parseInt(breakpoint.items)
            };
            if(breakpoint.margin>0){
                config[breakpoint.breakpoint+''].margin = parseInt(breakpoint.margin);
            }
            if(!!breakpoint.center){
                this.centermode = true;
            }
        }
        if(typeof config['0'] === "undefined" && Object.keys(config).length > 0){
            console.log(config[Object.keys(config)[0]]);
            config['0'] = config[Object.keys(config)[0]];
        }
        this.reponsiveConfig = config;
        this._uid = this.uid;
    },

    mounted() {
        this.$nextTick(() => {
            if (this.$_enableCarousel) {
                this.initializeCarousel();
            }
        });
    },

    methods:
        {
            initializeCarousel() {
                var owlOptions = {
                    dots: !!this.showDots,
                    items: 1,
                    mouseDrag: false,
                    loop: this.dataArray.length > 1,
                    lazyLoad: !this.disableLazyLoad,
                    nav: !!this.showNav,
                    responsive: this.reponsiveConfig,
                    navText: [
                        '<i id="owl-nav-text-left-'+this.sliderid+'" class="fa fa-'+this.navIcon+'-left" aria-hidden="true"></i>',
                        '<i id="owl-nav-text-right-'+this.sliderid+'" class="fa fa-'+this.navIcon+'-right" aria-hidden="true"></i>'
                        // '<i id="owl-nav-text-left" class="fa fa-chevron-left" aria-hidden="true"></i>',
                        // '<i id="owl-nav-text-right" class="fa fa-chevron-right" aria-hidden="true"></i>'
                    ],
                    dotsClass: 'owl-dots '+this.dotsClass,
                    navContainerClass: 'owl-nav container-max',
                    onTranslated(event) {
                        const element = event.target.querySelector(".owl-item.active img");

                        if (element && element.dataset.src && !element.src)
                        {
                            element.src = element.dataset.src;
                            element.removeAttribute("data-src");
                        }
                    },
                    onInitialized: event => {
                        if (this.showNav) {
                            // document.querySelector('#owl-nav-text-left-'+this.sliderid+'').parentElement.onclick = event => event.preventDefault();
                            // document.querySelector('#owl-nav-text-right-'+this.sliderid+'').parentElement.onclick = event => event.preventDefault();
                            // document.querySelector('#owl-nav-text-left').parentElement.onclick = event => event.preventDefault();
                            // document.querySelector('#owl-nav-text-right').parentElement.onclick = event => event.preventDefault();
                        }
                    }
                };

                if (this.animateOut !== "") {
                    owlOptions.animateOut = this.animateOut;
                }

                if (this.animateIn !== "") {
                    owlOptions.animateIn = this.animateIn;
                }

                // if (this.margin !== null) {
                //     owlOptions.margin = this.margin;
                // }

                if (this.autoplay !== null) {
                    owlOptions.autoplay = this.autoplay;
                }

                if (this.autoplayTimeout !== null) {
                    owlOptions.autoplayTimeout = this.autoplayTimeout;
                }

                if (this.autoheight !== null) {
                    owlOptions.autoheight = this.autoheight;
                }

                if (this.loop !== null) {
                    owlOptions.loop = this.loop;
                }

                // if (this.center !== null) {
                //     owlOptions.center = this.center;
                // }

                if (this.rightToLeft !== null) {
                    owlOptions.rtl = this.rightToLeft;
                }

                // if (this.dots !== null) {
                //     owlOptions.dots = this.dots;
                // }
                //
                // if (this.nav !== null) {
                //     owlOptions.nav = this.nav;
                // }
                //
                // if (this.dotsXs !== null) {
                //     owlOptions.responsive['0'].dots = this.dotsXs;
                // }
                //
                // if (this.dotsMd !== null) {
                //     owlOptions.responsive['768'].dots = this.dotsMd;
                // }
                //
                // if (this.dotsLg !== null) {
                //     owlOptions.responsive['1200'].dots = this.dotsLg;
                // }
                //
                // if (this.navXs !== null) {
                //     owlOptions.responsive['0'].nav = this.navXs;
                // }
                //
                // if (this.navMd !== null) {
                //     owlOptions.responsive['768'].nav = this.navMd;
                // }
                //
                // if (this.navLg !== null) {
                //     owlOptions.responsive['1200'].nav = this.navLg;
                // }
                //
                //
                // if (this.itemsXs !== null) {
                //     owlOptions.responsive['0'].items = this.itemsXs;
                // }
                //
                //
                // if (this.itemsMd !== null) {
                //     owlOptions.responsive['768'].items = this.itemsMd;
                // }
                //
                //
                // if (this.itemsLg !== null) {
                //     owlOptions.responsive['1200'].items = this.itemsLg;
                // }
                console.log('owl options', owlOptions);
                $("#owl-carousel-" + this._uid).owlCarousel(owlOptions);
            },


            getAltText(image) {
                const altText = image && image.alternate ? image.alternate : this.altText;

                return altText;
            },

            loadFirstImage() {
                const itemLazyImage = this.$refs.itemLazyImage;

                if (itemLazyImage) {
                    if (itemLazyImage.loadImage) {
                        itemLazyImage.loadImage();
                    } else if (itemLazyImage[0] && itemLazyImage[0].loadImage) {
                        itemLazyImage[0].loadImage();
                    }
                }
            },
            checkImageArray() {
                this.dataArray.filter((entry) => entry.href !== null);
            }
        }
});
