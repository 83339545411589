Vue.component("custom-item-search", {

    delimiters: ["${", "}"],

    props: [
        "template"
    ],

    data() {
        return {
            currentSearchString: "",
            preventSearch: false
        };
    },

    computed: Vuex.mapState({
        searchString: state => state.itemList.searchString
    }),

    created() {
        this.$options.template = this.template;
    },

    mounted() {
        this.$nextTick(() => {
            this.initAutocomplete();
        });
    },

    methods:
        {
            search() {
                if (this.currentSearchString.length &&
                    !this.preventSearch) {

                    let searchBaseURL = "/search?query=";

                    if (App.defaultLanguage != App.language) {
                        searchBaseURL = "/" + App.language + "/search?query=";
                    }

                    window.open(searchBaseURL + this.currentSearchString, "_self", false);

                }
                else {
                    this.preventSearch = false;
                }
            },

            openItem(suggestion) {
                this.preventSearch = true;
                window.open(this.$options.filters.itemURL(suggestion.data), "_self", false);
            },

            initAutocomplete() {
                $(".search-input").autocomplete({
                    serviceUrl: "/rest/io/item/search/autocomplete",
                    paramName: "query",
                    params: {template: "Ceres::ItemList.Components.ItemSearch"},
                    width: $(".search-box-shadow-frame").width(),
                    zIndex: 1070,
                    maxHeight: 310,
                    minChars: 2,
                    preventBadQueries: false,
                    onSelect: suggestion => {
                        this.$store.commit("setItemListSearchString", suggestion.value);
                        this.currentSearchString = suggestion.value;

                        if (App.config.search.forwardToSingleItem) {
                            this.openItem(suggestion);
                        }
                        else {
                            this.search();
                        }
                    },
                    beforeRender() {
                        $(".autocomplete-suggestions").width($(".search-box-shadow-frame").width());
                    },
                    formatResult: function (suggestion, currentValue) {
                        return suggestion.value;
                    },
                    transformResult: response => {
                        return this.transformSuggestionResult(response);
                    }
                });

                $(window).resize(() => {
                    $(".autocomplete-suggestions").width($(".search-box-shadow-frame").width());
                });
            },

            transformSuggestionResult(result) {
                result = JSON.parse(result);
                const suggestions =
                    {
                        suggestions: $.map(result.data.documents, dataItem => {
                            const value = this.$options.filters.itemName(dataItem.data);
                            return {
                                value: '<div class="preview-img"><img src="' + dataItem.data.images.all[0].urlPreview + '" alt=""></div><div class="label">' + value + '</div>',
                                data: dataItem.data
                            };
                            // return {
                            //     value: value,
                            //     data : dataItem.data
                            // };
                        })
                    };

                return suggestions;
            }
        }
});
