Vue.component("cfour-items", {

    delimiters: ["${", "}"],

    props: {
        template: {
            default: "#vue-cfour-productwall-items",
            type: String
        },
        cols: {
            default: 3
        },
        rows: {
            default: 4
        },
        variationids: {
            type: String,
            required: true
        },
        sorting: {
            default: "givenids"
        }
    },

    data()
{
        return {
            items: []
        };
    },

    created()
{
        this.$options.template = this.template;
        $.ajax({
            url: "/rest/CfourProductWall/items",
            method: "post",
            data: "ids=" + this.variationids + "&cols=" + this.cols + "&rows=" + this.rows + "&sorting=" + this.sorting
        }).done(data =>
{
            this.gridClass = 12 / this.cols;
            if (typeof data === "string")
{
                this.items = JSON.parse(data);
            }
            else
{
                this.items = data;
            }
            $(window).trigger("resize");
        });
    }

});
