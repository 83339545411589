Vue.component("cfour-manufacturer-slider", {

    delimiters: ["${", "}"],

    props: {
        imagedata: {type: Object},
        showDots: {type: [Number, Boolean], default:1},
        showNav: {type: [Number, Boolean], default:1},
        itemsXs: {type: Number, default:1},
        itemsMd: {type: Number, default:1},
        itemsLg: {type: Number, default:1},
        dots: {type: [Number, Boolean], default:0},
        dotsXs: {type: Number, default:0},
        dotsMd: {type: Number, default:0},
        dotsLg: {type: Number, default:0},
        nav: {type: Number, default:0},
        navXs: {type: Number, default:0},
        navMd: {type: Number, default:0},
        navLg: {type: Number, default:0},
        autoplayTimeout: {type: [Number, String], default:3000},
        autoplay: {type: Number, default:0},
        autoheight: {type: Number, default:0},
        center: {type: Number, default:0},
        loop: {type: Number, default:1},
        margin: {type: Number, default:0},
        rightToLeft: {type: Number, default:0},
        disableLazyLoad: {
            type: Boolean,
            default: false
        },
        enableCarousel: {type: String},
        template       : {type: String},
        uid:            {type: String}
    },

    data() {
        return {
            $_enableCarousel: true,
            _uid: "",
            manufacturers: []
        };
    },
    created() {
        this.$options.template = this.template;
        this.manufacturers = this.checkImageArray(this.imagedata.entries);
        this.manufacturers.sort(this.dynamicSort('position'));
        this.$_enableCarousel = true;
        this._uid = this.uid;
    },

    mounted() {
        this.$nextTick(() => {
            if (this.$_enableCarousel) {
                this.initializeCarousel();
            }
        });
    },

    methods:
        {
            dynamicSort(property) {
                var sortOrder = 1;
                if (property[0] === "-") {
                    sortOrder = -1;
                    property = property.substr(1);
                }
                return function (a, b) {
                    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                    return result * sortOrder;
                }
            },
            initializeCarousel() {
                // console.log("manufacturer length",this.manufacturers.length);
                // console.log("manufacturers",this.manufacturers);
                var owlOptions = {
                    dots: !!+this.showDots,
                    items: this.manufacturers.length,
                    mouseDrag: false,
                    loop: !!+this.loop,
                    lazyLoad: !this.disableLazyLoad,
                    margin: this.margin,
                    nav: !!+this.showNav,
                    autoplay: !!+this.autoplay,
                    responsive: {
                        '0': {
                            dots: true,
                            items: 1
                        },
                        '768': {
                            items: 3
                        },
                        '1200': {
                            items: 5
                        }
                    },
                    navText: [
                        `<i id="owl-nav-text-left-${this._uid}" class='fa fa-chevron-left' aria-hidden='true'></i>`,
                        `<i id="owl-nav-text-right-${this._uid}" class='fa fa-chevron-right' aria-hidden='true'></i>`
                    ],
                    onTranslated(event) {
                        const element = event.target.querySelector(".owl-item.active img");

                        if (element && element.dataset.src && !element.src)
                        {
                            element.src = element.dataset.src;
                            element.removeAttribute("data-src");
                        }
                    },
                    onInitialized: event => {
                        if (this.showNav) {
                            document.querySelector(`#owl-nav-text-left-${this._uid}`).parentElement.onclick = event => event.preventDefault();
                            document.querySelector(`#owl-nav-text-right-${this._uid}`).parentElement.onclick = event => event.preventDefault();
                        }
                    }
                };

                if (this.margin !== null) {
                    owlOptions.margin = this.margin;
                }

                if (this.autoplay !== null) {
                    owlOptions.autoplay = !!+this.autoplay;
                }

                if (this.autoplayTimeout !== null) {
                    owlOptions.autoplayTimeout = this.autoplayTimeout;
                }

                if (this.autoheight !== null) {
                    owlOptions.autoHeight = !!+this.autoheight;
                }

                if (this.loop !== null) {
                    owlOptions.loop = !!+this.loop;
                }

                if (this.center !== null) {
                    owlOptions.center = !!+this.center;
                }

                if (this.rightToLeft !== null) {
                    owlOptions.rtl = !!+this.rightToLeft;
                }

                if (this.dots !== null) {
                    owlOptions.dots = !!+this.dots;
                }

                if (this.nav !== null) {
                    owlOptions.nav = !!+this.nav;
                }

                if (this.dotsXs !== null) {
                    owlOptions.responsive['0'].dots = this.dotsXs;
                }

                if (this.dotsMd !== null) {
                    owlOptions.responsive['768'].dots = this.dotsMd;
                }

                if (this.dotsLg !== null) {
                    owlOptions.responsive['1200'].dots = this.dotsLg;
                }

                if (this.navXs !== null) {
                    owlOptions.responsive['0'].nav = this.navXs;
                }

                if (this.navMd !== null) {
                    owlOptions.responsive['768'].nav = this.navMd;
                }

                if (this.navLg !== null) {
                    owlOptions.responsive['1200'].nav = this.navLg;
                }


                if (this.itemsXs !== null) {
                    owlOptions.responsive['0'].items = this.itemsXs;
                }


                if (this.itemsMd !== null) {
                    owlOptions.responsive['768'].items = this.itemsMd;
                }


                if (this.itemsLg !== null) {
                    owlOptions.responsive['1200'].items = this.itemsLg;
                }
                console.log('owl options',owlOptions);
                $("#owl-carousel-"+this._uid).owlCarousel(owlOptions);},


            getAltText(image)
            {
                const altText = image && image.alternate ? image.alternate : this.altText;

                return altText;
            },

            loadFirstImage()
            {
                const itemLazyImage = this.$refs.itemLazyImage;

                if (itemLazyImage)
                {
                    if (itemLazyImage.loadImage)
                    {
                        itemLazyImage.loadImage();
                    }
                    else if (itemLazyImage[0] && itemLazyImage[0].loadImage)
                    {
                        itemLazyImage[0].loadImage();
                    }
                }
            },
            checkImageArray(data) {
                return data.filter(entry => entry.logo.length !== 0);
            }
        }
});
